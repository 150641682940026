import Checkbox from 'Shared/Checkbox/Checkbox'

const CheckInstallment = ({ installment, isSelected, onToggleSelect }) => {
  const { id } = installment
  const installmentName = `installment-check-${id}`

  return (
    <Checkbox className="visually-hidden" classNameWrapper="position-relative" color="blue-dark"
              handleClick={() => onToggleSelect(id)} id={installmentName} isChecked={isSelected}
              message="Select Installment" name={installmentName} />
  )
}

export default CheckInstallment
