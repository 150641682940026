import apolloClient, { ApolloProvider } from 'Utils/apolloClient'
import Wrapper from './Wrapper'

const App = props => {
  const { shouldVoid, reservation } = props

  return (
    <ApolloProvider client={apolloClient}>
      <Wrapper shouldVoid={shouldVoid} reservation={reservation} />
    </ApolloProvider>
  )
}

export default App
