import { useDataLoadingSubscription } from 'Shared/Hooks/useDataLoadingSubscription'
import { Progress } from 'reactstrap'

const Processing = () => {
  const scope = { currentUser: `import_reservations_by_${window.roverData.campgroundId}` }
  const { isCompleted, percentage } = useDataLoadingSubscription(scope)
  const progressColor = isCompleted ? 'success' : 'primary'
  const text = isCompleted ? 'Process Completed!' : 'Processing Reservations...'

  return (
    <div className="processing">
      <p className="text-center">{text}</p>

      <Progress color={progressColor} value={percentage} />
    </div>
  )
}

export default Processing
