import { useState } from 'react'
import { useMutation } from 'Shared/Hooks/useApolloClient'
import { ReservationCheckOutAndVoid } from './Mutations/ReservationCheckOutAndVoidMutation'
import { Button } from 'reactstrap'
import VoidInvoicesModal from './VoidInvoicesModal'
import 'Styles/Button.scss'
import 'Styles/Text.scss'

const Wrapper = ({ shouldVoid, reservation }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [voidAndCheckout, { loading, error }] = useMutation(ReservationCheckOutAndVoid, {
    onCompleted: data => {
      const result = data?.reservationVoidAndCheckOut
      if (result?.success)
        toggle()
        window.location.reload()
    },
  })

  const toggle = () => setIsOpen(prevState => !prevState)

  const handleCheckOut = () => {
    if (shouldVoid) return toggle()

    performCheckOutAndVoid()
  }

  const performCheckOutAndVoid = (selectedInstallments = []) => {
    voidAndCheckout({
      variables: {
        reservationId: reservation.id,
        selectedInstallments,
      },
    })
  }

  return (
    <>
      <Button className="me-0" color="cyan" onClick={() => handleCheckOut()}>Check Out</Button>

      <VoidInvoicesModal {...{ error, isOpen, loading, performCheckOutAndVoid, toggle }} />
    </>
  )
}

export default Wrapper
