import { gql } from 'Utils/apolloClient'

const onImportReservations = gql`
  subscription onImportReservations {
    importReservations {
      errorMessage
      errorsCount
      successCount
    }
  }
`

export default onImportReservations
