import { useState } from 'react'
import cnames from 'classnames'
import PropTypes from 'prop-types'
import { Button, FormGroup, Input, InputGroup, InputGroupText, Label } from 'reactstrap'
import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'
import FormGroupField from 'Shared/Formik/FormGroupField'
import './FormPasswordInput.scss'

const FormPasswordInput = ({ className, disabled, formGroupClasses, inputClasses, isRequired, isTextType, label,
                             labelClasses, max, name, onChange, placeholder, showErrors, showEye,
                             sizeInput }) => {
  const [showPassword, setShowPassword] = useState()
  const classes = {
    formGroup: cnames('mb-0', formGroupClasses),
    input: cnames('control-input', { 'border-end-0': showEye }, inputClasses),
    label: cnames('main-label', labelClasses),
    seeContainer: cnames('see-container p-0', { 'color-disabled': disabled }),
  }
  const type = (showPassword || isTextType) ? 'text' : 'password'
  const iconData = {
    text: { color: '#1F4965', icon: ['far', 'eye-slash'] },
    password: { color: '#4B6D83', icon: ['far', 'eye'] },
  }

  const handlePassword = () => setShowPassword(prevState => !prevState)

  return (
    <FormGroupField className={className} name={name} showErrors={showErrors}>
      {({ field, form, hasErrorClass, isInvalidClass }) => {
        const initValue = field.value ?? ''
        const classesInput = cnames(classes.input, hasErrorClass)

        const handleOnChange = ({ target }) => {
          const newValue = target.value
          if (onChange) return onChange(newValue)
          if (newValue.length <= max) form.setFieldValue(name, newValue)
        }

        return (
          <FormGroup className={classes.formGroup}>
            {label && (
              <Label className={classes.label} for={name}>
                {label}

                {isRequired && <span className="text-danger ms-1">*</span>}
              </Label>
            )}

            <InputGroup className="flex-nowrap">
              <Input {...field} bsSize={sizeInput} className={classesInput} disabled={disabled} id={name}
                     invalid={isInvalidClass} max={max} name={name} onChange={handleOnChange}
                     placeholder={placeholder} type={type} value={initValue} />

              {showEye && (
                <InputGroupText className={classes.seeContainer}>
                  <Button className="see-data-button" color="link" onClick={handlePassword}>
                    <FontAwesomeIcon color={iconData[type].color} icon={iconData[type].icon} />
                  </Button>
                </InputGroupText>
              )}
            </InputGroup>
          </FormGroup>
        )
      }}
    </FormGroupField>
  )
}

FormPasswordInput.defaultProps = {
  disabled: false,
  formGroupClasses: null,
  inputClasses: null,
  isRequired: false,
  label: '',
  labelClasses: '',
  max: 255,
  onChange: null,
  placeholder: '',
  showErrors: false,
  showEye: false,
  sizeInput: 'md',
}

FormPasswordInput.propTypes = {
  disabled: PropTypes.bool,
  formGroupClasses: PropTypes.string,
  inputClasses: PropTypes.string,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  labelClasses: PropTypes.string,
  max: PropTypes.number,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  showErrors: PropTypes.bool,
  showEye: PropTypes.bool,
  sizeInput: PropTypes.string,
}

export default FormPasswordInput
