import { useState } from 'react'
import { Button, Input } from 'reactstrap'
import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'
import Loading from 'Shared/Loading'

const UploadFile = ({ loading, execute }) => {
  const [selectedFile, setSelectedFile] = useState()

  const handleFileChange = event => {
      const file = event.target.files[0]
      setSelectedFile(file)
    }

    const handleUpload = () => {
      execute({ variables: { file: selectedFile, slug: window.roverData.slug } })
      setSelectedFile()
    }

  return (
    <div className="upload-file">
      <Input className="upload-reservations text-secondary border border-1 rounded mb-3"
             data-formats="[csv]" id="reservations" name="reservations" onChange={handleFileChange}
             type="file" />

      <Button className="col-12 col-md-auto" color="salmon" disabled={!selectedFile} onClick={handleUpload}>
        {loading ? (
          <Loading size="sm" />
        ) : (
          <>
            <FontAwesomeIcon className="me-2" icon="cloud-arrow-up" />

            Upload File
          </>
        )}
      </Button>
    </div>
  )
}
export default UploadFile
