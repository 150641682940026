import { gql } from 'Utils/apolloClient'

const importReservationsMutation = gql`
  mutation ImportReservationsMutation($file: Upload!, $slug: ID!) {
    importReservations(input: { campgroundId: $slug, file: $file }) {
      wasQueued
    }
  }
`

export default importReservationsMutation
