import { useEffect, useState } from 'react'
import { useSubscription } from '@apollo/client'
import onImportReservations from 'Shared/ReservationAdminTools/Subscriptions/onImportReservations'

export const useImportReservationsSubscription = () => {
  const [result, setResult] = useState({})
  const { data } = useSubscription(onImportReservations, {
    variables: {
      scope: {
        currentCampground: `import_reservations_by_${window.roverData.campgroundId}`,
      },
    },
  })

  const resetResult = () => setResult({})

  useEffect(() => {
    if (data?.importReservations) setResult(data?.importReservations)
  }, [data?.importReservations])

  return { resetResult, result }
}
