import { useState } from 'react'
import apolloClient, { ApolloProvider } from 'Utils/apolloClient'
import { Button } from 'reactstrap'
import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'
import ModalWrapper from 'Shared/ReservationAdminTools/Components/ModalWrapper'

const ReservationAdminTools = () => {
  const [isOpen, setIsOpen] = useState(false)
  const container = document.getElementById('react-data-container')
  const { id, slug, token } = container.dataset
  window.roverData = { campgroundId: id, slug, token }

  const toggle = () => setIsOpen(!isOpen)

  return (
    <ApolloProvider client={apolloClient}>
      <Button className="btn-icon" color="salmon" data-bs-toggle="modal"
              data-bs-target="#reservation-admin-tools-modal" onClick={toggle}>
        <FontAwesomeIcon className="py-2" icon="cloud-arrow-up" />

        <span className="align-middle ps-3">Import Reservations by CSV</span>
      </Button>

      <ModalWrapper isOpen={isOpen} toggle={toggle} />
    </ApolloProvider>
  )
}

export default ReservationAdminTools
