import { useEffect } from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { useMutation } from 'Shared/Hooks/useApolloClient'
import { useImportReservationsSubscription } from 'Shared/ReservationAdminTools/Hooks/useImportReservationsSubscription'
import importReservationsMutation from 'Shared/Mutations/ImportReservationsMutation'
import AlertDismiss from 'Shared/Alert/AlertDismiss/AlertDismiss'
import CloseModalButton from 'Shared/CloseModalButton'
import Processing from 'Shared/ReservationAdminTools/Components/Processing'
import UploadFile from 'Shared/ReservationAdminTools/Components/UploadFile'
import 'Styles/SalmonHeaderModal.scss'

const ModalWrapper = ({ isOpen, toggle }) => {
  const closeButton = (<CloseModalButton isColorWhite onClick={toggle} />)
  const { resetResult, result } = useImportReservationsSubscription()
  const [execute, { data, loading, reset }] = useMutation(importReservationsMutation)
  const { wasQueued } = data?.importReservations || {}

  const onExecute = variables => {
    resetResult()
    reset()
    execute(variables)
  }

  const onToggle = () => {
    if (wasQueued) return

    resetResult()
    reset()
    toggle()
    window.location.reload()
  }

  useEffect(() => {
    if (result.errorMessage) reset()
  }, [result.errorMessage])

  return (
    <Modal centered className="salmon-header-modal" id="upload-reservations-modal" isOpen={isOpen}
           toggle={onToggle}>
      <ModalHeader className="modal-header" close={closeButton} toggle={onToggle}>
        <span className="text-white">Upload Reservations by CSV</span>
      </ModalHeader>

      <ModalBody>
        {result.errorMessage && (
          <AlertDismiss className="alert-danger text-danger">
            {result.errorMessage}
          </AlertDismiss>
        )}

        {wasQueued && !result.errorMessage ? (
          <Processing />
        ) : (
          <UploadFile execute={onExecute} loading={loading} />
        )}
      </ModalBody>
    </Modal>
  )
}

export default ModalWrapper
