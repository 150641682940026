import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap'
import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'

const CheckOutAndVoidConfirmationModal = ({ allSelected, installments, isOpen, selectedInstallments,
                                            toggle, onConfirm }) => {
  const modalText = allSelected ? 'all invoices' : 'the following invoices'
  const handleConfirm = () => {
    onConfirm(selectedInstallments)
    toggle()
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalBody>
        <div className="row">
          <span>Are you sure you want to <strong>void</strong> {modalText} for this reservation?</span>

          <span>This action cannot be undone</span>
        </div>

        <ul className="mt-2">
          {installments?.map(installment => (
            <li className="mb-2" key={installment.id}>
              ID: <strong>{installment.id}</strong> - <i className="text-gray">{installment.total.display}</i>
            </li>
          ))}
        </ul>
      </ModalBody>

      <ModalFooter className="d-flex justify-content-between">
        <Button color="outline-dark-blue" onClick={toggle}>No, Go Back</Button>

        <Button color="blue-dark" onClick={handleConfirm}>
          <FontAwesomeIcon className="me-2" icon="ban" />
          Yes, Void & Check Out
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default CheckOutAndVoidConfirmationModal
