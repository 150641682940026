import { gql } from 'Utils/apolloClient'

const ReservationInstallmentsQuery = gql`
  query ReservationInstallmentsQuery($slug: String!, $uuid: ID!, $isEngineAdmin: Boolean) {
    campground(slug: $slug, isEngineAdmin: $isEngineAdmin) {
      id
      reservation(uuid: $uuid) {
        id
        installmentsStateOpen {
          dueOn
          id
          links{
            installmentPath
          }
          number
          paidAt
          sentAt
          state
          total {
            display
            displayWithCode
          }
        }
      }
    }
  }`

export default ReservationInstallmentsQuery
