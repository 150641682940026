import { useEffect, useState } from 'react'
import { useLazyQueryNoCache } from 'Shared/Hooks/useApolloClient/useLazyQueryNoCache'
import ReservationInstallmentsQuery from './Queries/ReservationInstallmentsQuery'
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from 'reactstrap'
import AlertErrors from 'Shared/Alert/AlertErrors'
import Checkbox from 'Shared/Checkbox/Checkbox'
import CheckOutAndVoidConfirmationModal from './CheckOutAndVoidConfirmationModal'
import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'
import InstallmentCard from './InstallmentCard'
import Loading from 'Shared/Loading'
import './VoidInvoicesModal.scss'

const VoidInvoicesModal = ({ isOpen, mutationError, mutationLoading, performCheckOutAndVoid, toggle }) => {
  const { engineName, slug, uuid } = window.roverData
  const [getReservation, { data, error, loading }] = useLazyQueryNoCache(ReservationInstallmentsQuery)
  const installments = data?.campground?.reservation?.installmentsStateOpen
  const [selectedInstallments, setSelectedInstallments] = useState([])
  const [confirmationOpen, setConfirmationOpen] = useState(false)
  const selectedCount = selectedInstallments.length
  const allInstallmentsChecked = selectedCount === installments?.length
  const selectedInstallmentsToVoid = installments?.filter(installment =>
                                                          selectedInstallments.includes(installment.id))
  const toggleConfirmationOpen = () => setConfirmationOpen(prevState => !prevState)

  useEffect(() => {
    if (isOpen) getReservation({ variables: { engineName, slug, uuid } })
  }, [isOpen])

  const handleToggleInstallment = id => {
    setSelectedInstallments(prevSelected => {
      if (prevSelected.includes(id)) return prevSelected.filter(installmentId => installmentId !== id)
      return [...prevSelected, id]
    })
  }

  const toggleSelectAll = () => {
    if (selectedCount === installments?.length) setSelectedInstallments([])
    else setSelectedInstallments(installments.map(i => i.id))
  }

  const handleVoidAndCheckout = () => {
    if (selectedCount >= 1 && !confirmationOpen) setConfirmationOpen(true)
    else
      performCheckOutAndVoid(selectedInstallments)
  }

  const getCheckoutButtonText = () => {
    if (allInstallmentsChecked) return 'Void All Invoices & Check Out'
    if (selectedCount > 1) return `Void ${selectedCount} Invoices & Check Out`
    return selectedCount === 1 ? 'Void 1 Invoice & Check Out' : 'Check Out Without Voiding'
  }

  return (
    <>
      <Modal className="void-invoices-modal" isOpen={isOpen} size="lg" toggle={toggle}>
        <ModalHeader className="shadow-sm" toggle={toggle}>
          Void Invoices & Check Out
        </ModalHeader>

        <ModalBody className="bg-light pb-0">
          {(error && !data) && (
            <AlertErrors className="m-3" graphQLErrors={error} />
          )}

          {(mutationError) && (
            <AlertErrors className="m-3" graphQLErrors={mutationError} />
          )}

          {loading && (
            <Loading className="text-center mb-2" iconClass="mt-5" size="2x" />
          )}

          {!loading && (
            <div>
              <p>This reservation has open invoices, do you want to void them before checking out?</p>

              <Checkbox classNameWrapper="p-0 mb-1 ms-1" classNameInput="ms-3" color="blue-dark"
                        handleClick={toggleSelectAll} isChecked={allInstallmentsChecked} labelClassname="ms-2"
                        message="Void All" name="void all" />
            </div>
          )}

          <div className="installments-container container">
            {installments && installments.map(installment => (
              <InstallmentCard id={installment.id} installment={installment}
                               isSelected={selectedInstallments.includes(installment.id)} key={installment.id}
                               onToggleSelect={handleToggleInstallment} />
            ))}
          </div>
        </ModalBody>

        <ModalFooter className="justify-content-between">
          <Button color="outline-dark-blue" onClick={toggle}>Cancel</Button>

          <Button color="blue-dark" disabled={mutationLoading} onClick={handleVoidAndCheckout}>
            {selectedCount >= 1 && (
              <FontAwesomeIcon className="me-2" icon="ban" />
            )}
            {getCheckoutButtonText()}
          </Button>
        </ModalFooter>
      </Modal>

      <CheckOutAndVoidConfirmationModal allSelected={allInstallmentsChecked}
                                        installments={selectedInstallmentsToVoid} isOpen={confirmationOpen}
                                        selectedInstallments={selectedInstallments}
                                        toggle={toggleConfirmationOpen} onConfirm={handleVoidAndCheckout} />
    </>
  )
}

export default VoidInvoicesModal
