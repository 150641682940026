import { capitalizeFirstLetter } from 'Utils/Strings'
import CheckInstallment from './CheckInstallment'
import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'

const CLASS_NAME = 'installment-list--installment align-items-center bg-white border border-1 mb-3 py-3 px-2 rounded row shadow-sm'

const InstallmentCard = ({ id, installment, isSelected, onToggleSelect }) => {
  const { dueOn, state, links } = installment
  const { installmentPath } = links
  const formattedDueOn = dueOn?.slice(5)?.replace('-', '/')
  const formattedState = capitalizeFirstLetter(state)

  return (
    <div className={CLASS_NAME} id={id}>
      <div className="installment--total col col-lg-2 d-flex">
        <CheckInstallment installment={installment} isSelected={isSelected} onToggleSelect={onToggleSelect} />

        <span className="fst-italic fw-bold ms-3">{installment.total.display}</span>
      </div>

      <div className="col col-lg-2 fst-italic">
        <span className="small text-gray">Due On:</span>

        <span className="ms-2">{formattedDueOn}</span>
      </div>

      <div className="col col-lg-3 fst-italic">
        <span className="small text-gray">ID:</span>

        <span className=" ms-2">{id}</span>
      </div>

      <div className="col col-lg-4 fst-italic">
        <span className="small text-gray">Current Status:</span>

        <span className="ms-2">{formattedState}</span>
      </div>

      <div className="col col-lg-1 text-end">
        <a href={installmentPath}>
          <FontAwesomeIcon className="small text-facebook ms-2" icon="arrow-up-right-from-square" />
        </a>
      </div>
    </div>
  )
}

export default InstallmentCard
