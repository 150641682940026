import { gql } from 'Utils/apolloClient'

export const ReservationCheckOutAndVoid = gql`
  mutation ReservationCheckOutAndVoid($reservationId: ID!, $selectedInstallments: [ID!]!) {
    reservationCheckOutAndVoid(input: { reservationId: $reservationId,
                                        selectedInstallments: $selectedInstallments }) {
      success
      message
    }
  }
`
